import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import Layout from "../../layout";
import SEO from "../../components/SEO/SEO";
import ToolMenu from "../../components/ToolMenu";
import config from "../../../data/SiteConfig";
import { Divider, Button, Form, Input, InputNumber, Space, Radio } from "antd";
import { CSVLink } from "react-csv";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const ToolPage = () => {
  const [wattHours, setWattHours] = useState({ [0]: 0 });
  const [kiloWattHours, setkiloWattHours] = useState(0);
  const [totalWattHours, setTotalWattHours] = useState("");
  const [csvData, setCsvData] = useState(["empty", "csv"]);
  const [voltDefault, setVoltDefault] = useState(3.2);

  const onValChange = (changedValues, allValues) => {
    let rows = [];
    const apps = allValues.appliances;
    for (let row = 0; row < apps.length; row++) {
      const app = apps[row];
      if (app && app.voltage && app.amphours) {
        const wh = app.voltage * app.amphours;
        const kwh = wh / 1000;
        const kwhprice = app.cost / kwh;

        rows[row] = kwh.toFixed(2);
        apps[row]["KWH"] = kwh.toFixed(2);
        apps[row]["KWH Cost"] = kwhprice.toFixed(2);
        if (kiloWattHours > 0) {
          const battcost = kwhprice * kiloWattHours;
          apps[row]["Battery Cost"] = battcost.toFixed(2);
        }
      }
    }
    setTotalWattHours("");
    setCsvData({ apps });
    setWattHours(rows);
  };

  const calculateWattHours = () => {
    // calculate watt hours
    let whTotal = 0;
    for (let i = 0; i < wattHours.length; i++) {
      whTotal = whTotal + wattHours[i];
    }
    setTotalWattHours(
      <div>
        <CsvButton />
      </div>
    );
  };

  const CsvButton = () => {
    if (typeof csvData.apps != "undefined") {
      const headers = [
        { label: "Cell Name", key: "cell" },
        { label: "Volts per cell", key: "voltage" },
        { label: "Amp Hours", key: "amphours" },
        { label: "Kilowatt Hours", key: "KWH" },
        { label: "Cost per Cell", key: "cost" },
        { label: "Cost per KWH", key: "KWH Cost" },
      ];

      return (
        <CSVLink
          data={csvData.apps}
          filename={"holobiontLoadCalc.csv"}
          target="_blank"
          headers={headers}
        >
          Download this as a CSV file
        </CSVLink>
      );
    } else {
      console.log(csvData);
      return <p>no data?</p>;
    }
  };

  const onCellVoltagepresetChange = (val) => {
    setVoltDefault(val.cellvoltage);
  };

  const onKWHChange = (val) => {
    setkiloWattHours(val.battery_size);
  };

  return (
    <Layout>
      <div className="index-container">
        <Helmet title={config.siteTitle} />
        <SEO />
        <ToolMenu />
        <Divider />
        <Form onValuesChange={onCellVoltagepresetChange}>
          <Form.Item
            label="Default Cell Voltage"
            name="cellvoltage"
            initialValues="3.2"
          >
            <Radio.Group>
              <Radio.Button value="3.2">LiFePo4 (3.2)</Radio.Button>
              <Radio.Button value="3.7">
                Lithium Ion / LiPo / NMC (3.7)
              </Radio.Button>
              <Radio.Button value="2.4">LTO (2.4)</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>

        <Form name="basic" autoComplete="off" onValuesChange={onKWHChange}>
          <Form.Item label="Battery size in kilowatt hours" name="battery_size">
            <InputNumber placeholder="KWH" />
          </Form.Item>
        </Form>

        <Divider />

        <h3>
          Battery cost{" "}
          {kiloWattHours ? ` for a ${kiloWattHours} KWH Battery` : ""}
        </h3>
        <Form
          name="dynamic_form_nest_item"
          onFinish={calculateWattHours}
          onValuesChange={onValChange}
          autoComplete="off"
        >
          <div style={{ display: "flex", marginBottom: 8 }} align="baseline">
            <span style={{ width: `230px` }}> Cell name</span>
            <span style={{ width: `97px` }}> Amp Hours</span>
            <span style={{ width: `100px` }}> Cell Voltage</span>
            <span> Cost per Cell</span>
          </div>
          <Form.List name="appliances">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item {...restField} name={[name, "cell"]}>
                      <Input placeholder="Cell name" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "amphours"]}
                      rules={[{ required: true, message: "Amp Hours please" }]}
                    >
                      <InputNumber placeholder="Amp Hours" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "voltage"]}
                      initialValue={voltDefault}
                      rules={[
                        { required: true, message: "Missing cell voltage" },
                      ]}
                    >
                      <InputNumber placeholder="Volts per cell" max={60} />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "cost"]}
                      rules={[
                        { required: true, message: "Missing cost per cell" },
                      ]}
                    >
                      <InputNumber placeholder="Cost per cell" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      rules={[{ message: "watt hours" }]}
                      value={wattHours[name]}
                      shouldUpdate
                    >
                      {() => {
                        if (
                          typeof csvData.apps[name] != "undefined" &&
                          csvData.apps[name]["KWH"] > 0
                        ) {
                          return (
                            <div
                              style={{
                                padding: `5px`,
                                border: `1px #aadaff solid`,
                                background: `#aadaff`,
                              }}
                            >
                              {wattHours[name]} KWH
                            </div>
                          );
                        }
                      }}
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      rules={[{ message: "$/KWH" }]}
                      value={wattHours[name]}
                      shouldUpdate
                    >
                      {() => {
                        if (
                          typeof csvData.apps[name] != "undefined" &&
                          csvData.apps[name]["cost"] > 0
                        ) {
                          return (
                            <div
                              style={{
                                padding: `5px`,
                                border: `1px #aadaff solid`,
                                background: `#aadaff`,
                              }}
                            >
                              ${csvData.apps[name]["KWH Cost"]}/KWH
                            </div>
                          );
                        }
                      }}
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      rules={[{ message: "cost for battery build" }]}
                      shouldUpdate
                    >
                      {() => {
                        if (
                          typeof csvData.apps[name] != "undefined" &&
                          kiloWattHours > 0 &&
                          csvData.apps[name]["Battery Cost"] > 0 &&
                          csvData.apps[name]["cost"] > 0
                        ) {
                          return (
                            <div
                              style={{
                                padding: `5px`,
                                border: `1px #aadaff solid`,
                                background: `#aadaff`,
                              }}
                            >
                              ${csvData.apps[name]["Battery Cost"]} cost for{" "}
                              {kiloWattHours}KWH
                            </div>
                          );
                        }
                      }}
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add cell
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Build CSV
            </Button>
          </Form.Item>
        </Form>
        {totalWattHours}
      </div>
    </Layout>
  );
};

export default ToolPage;
